<template>
  <div class="modal fade show" tabindex="-1" style="display: block; background: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar Configuraciones</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form>
            <div
              v-for="(config, index) in localConfigs"
              :key="config.id"
              class="mb-3"
            >
              <label :for="'config-' + config.id" class="form-label">{{ config.name }}</label>
              <input
                v-model="localConfigs[index].body"
                type="text"
                :id="'config-' + config.id"
                class="form-control"
                placeholder="Ingrese datos para body"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
          <button type="button" class="btn btn-primary" @click="saveChanges">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    configs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localConfigs: [], // Copia local de las configuraciones
    };
  },
  watch: {
    configs: {
      immediate: true,
      handler(newConfigs) {
        this.localConfigs = JSON.parse(JSON.stringify(newConfigs)); // Crear una copia profunda
      },
    },
  },
  methods: {
    saveChanges() {
      this.$emit("save", this.localConfigs); // Enviar los datos editados al padre
    },
    closeModal() {
      this.$emit("close"); // Cerrar el modal
    },
  },
};
</script>

<style scoped>
.modal.fade.show {
  display: block;
  background: rgba(0, 0, 0, 0.5); /* Fallback para el overlay */
}
</style>
