<template>
  <div class="text-center">
      <div v-if="loading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
              <span class="visually-hidden">Cargando...</span>
          </div>
      </div>
      
      <div v-else-if="error" class="alert alert-danger" role="alert">
          {{ error }}
      </div>

      <div v-else>
          <div class="form-check form-check-inline m-2" v-for="store in uniqueStores" :key="store.name">
              <input 
                  class="form-check-input" 
                  type="radio" 
                  :name="name"
                  :id="inputId + store.name" 
                  :value="store.name"
                  v-model="inputValue" 
                  @change="handleShopChange" />
              <label class="form-check-label" :for="inputId + store.name">{{ store.name }}</label>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ShopsInput',
  props: {
      value: {
          type: String,
          default: ''
      },
      name: {
          type: String,
          default: ""
      },
      inputId: {
          type: String,
          required: true
      },
      apiUrl: {
          type: String,
          required: true
      }
  },
  data() {
      return {
          stores: [],
          loading: false,
          error: null
      };
  },

  methods: {
      handleShopChange() {
          this.$emit('shop-changed', this.inputValue);
      },

      async fetchStores() {
          this.loading = true;
          this.error = null;
          
          try {
              const url = this.appDomainApi + "shops/list/name";

              axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.stores = res.data
                })
                .catch(err => {
                    console.error(err);
                })

      
          } catch (err) {
              this.error = 'Error al cargar las tiendas. Por favor, intente más tarde.';
              console.error('Error fetching stores:', err);
          } finally {
              this.loading = false;
          }
      }
  },
  
  computed: {
      inputValue: {
          get() {
              return this.value;
          },
          set(value) {
              this.$emit("input", value);
          }
      },
      uniqueStores() {
          const storesMap = new Map();
          
          this.stores.forEach(store => {
              if (!storesMap.has(store.name)) {
                  storesMap.set(store.name, {
                      name: store.name,
                      slug: store.slug,
                      status: store.status
                  });
              }
          });
          
          return Array.from(storesMap.values());
      }
  },

  created() {
      this.fetchStores();
  }
};
</script>