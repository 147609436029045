<template>
  <div class="store-container">
    <div style="display: flex;">
      <h2>Lista de Tiendas</h2>

      <button
        type="button"
        class="btn btn-primary"
        @click="addShop"
      >
        Add
      </button>

      <div>modal</div>


  </div>
    
    <div class="copy-section" v-if="selectedStore">
      <div class="copy-header">
        <h3>Copiar productos de: {{ selectedStore.name }}</h3>
        <button @click="clearSelection" class="cancel-button">
          <span>&times;</span>
        </button>
      </div>

      <select v-model="targetStoreId" class="select-store">
        <option value="">Seleccionar tienda destino</option>
        <option 
          v-for="store in storesExceptSelected" 
          :key="store.id" 
          :value="store.id"
        >
          {{ store.name }} ({{ store.slug }}) - {{ store.products_count }}
        </option>
      </select>
      
      <div class="button-group">
        <button 
          @click="copyProducts" 
          :disabled="!targetStoreId || isLoading"
          class="copy-button"
        >
          {{ isLoading ? 'Copiando...' : 'Copiar Productos' }}
        </button>
      </div>
    </div>

    <StoreItem 
      v-for="store in mergedStores" 
      :key="store.id" 
      :store="store"
      :isSelected="isStoreSelected(store.id)"
      @select-store="handleStoreSelect"
      @product-removed="handleProductRemoved"
    />


  </div>
</template>

<script>
import axios from 'axios'
import StoreItem from '@/components/Shops/StoresItem.vue'

export default {
  name: 'StoreList',

  components: {
    StoreItem
  },

  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    stores: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedStore: null,
      targetStoreId: '',
      isLoading: false,
      shop_store_new:{
        name:null,
        slug:null,
      }
    }
  },

  computed: {
    mergedStores() {
      const mergedMap = this.data.reduce((acc, store) => {
        const { name } = store

        // Asegurarse de que los arrays existan
        const products = Array.isArray(store.products) ? store.products : []
        const metaTags = Array.isArray(store.mata_tag) ? store.mata_tag : []
        const pageConfigs = Array.isArray(store.mata_tag) ? store.mata_tag : []

        if (!acc[name]) {
          // Inicializar primera ocurrencia
          acc[name] = {
            ...store,
            // slugs: [store.slug, store.products_count],
            slugs: [store.slug],
            products: products,
            mata_tag: metaTags,
            page_configs: pageConfigs,
          }
        } else {
          // Fusionar ocurrencias adicionales
          acc[name].slugs.push(store.slug)

          // Asegurar unicidad de productos basados en `id`
          const existingProductIds = new Set(acc[name].products.map(p => p.id));
          acc[name].products = [
            ...acc[name].products,
            ...products.filter(p => !existingProductIds.has(p.id)),
          ];

          // acc[name].slugs = [...new Set([...acc[name].slugs, store.name, store.slug])]
          // acc[name].products = [...new Set([...acc[name].products, ...products])]

          acc[name].mata_tag = [...new Set([...acc[name].mata_tag, ...metaTags])]
          acc[name].page_configs = [...new Set([...acc[name].page_configs, ...pageConfigs])]
        }

        return acc
      }, {})

      return Object.values(mergedMap)
    },
    storesExceptSelected() {
      if (!this.selectedStore) {
        return this.stores;
      }
      return this.stores.filter(store => 
        store.id !== this.selectedStore.id
      )
    }
  },
  methods: {
    async addShop(){
      document.getElementById('btnModalCreateOrder').click();
    },
    async shop_store(){
      console.log("addShop")

      try {
        this.isLoading = true

        const url = this.appDomainApi + "shops/list/store-shop";
        const data = this.shop_store_new;

        const response = await axios.post(url, {data})

        if (response.data.success) {
          alert('Productos copiados exitosamente')
        }
      } catch (error) {
        console.error('Error al copiar productos:', error)
        alert('Error al copiar productos')
      } finally {
        this.isLoading = false
      }


    },
    handleStoreUpdate(updatedStore) {
      this.$emit('store-updated', updatedStore)
    },
    isStoreSelected(storeId) {
      return this.selectedStore && this.selectedStore.id === storeId;
    },
    handleStoreSelect(store) {
      // Si ya está seleccionada, la deseleccionamos
      if (this.selectedStore && this.selectedStore.id === store.id) {
        this.clearSelection()
      } else {
        this.selectedStore = store
        this.targetStoreId = ''
      }
    },
    clearSelection() {
      this.selectedStore = null
      this.targetStoreId = ''
      this.isLoading = false
    },
    async copyProducts() {
      if (!this.selectedStore || !this.targetStoreId) {
        return;
      }
      
      try {
        this.isLoading = true

        const url = this.appDomainApi + "shops/list/copy-products";

        const response = await axios.post(url, {
          source_store_id: this.selectedStore.id,
          target_store_id: this.targetStoreId
        })

        if (response.data.success) {
          this.$emit('products-copied', {
            sourceStore: this.selectedStore,
            targetStoreId: this.targetStoreId
          })
          alert('Productos copiados exitosamente')
          this.clearSelection()
        }
      } catch (error) {
        console.error('Error al copiar productos:', error)
        alert('Error al copiar productos')
      } finally {
        this.isLoading = false
      }
    },
    // Limpiar la selección cuando el componente se desmonta
    beforeUnmount() {
      this.clearSelection()
    },
    handleProductRemoved({ storeId, productId }) {
      // Actualizar el estado local después de eliminar
      const storeIndex = this.stores.findIndex(store => store.id === storeId)
      if (storeIndex !== -1) {
        const store = this.stores[storeIndex]
        store.products = store.products.filter(product => product.id !== productId)
        
        // Forzar actualización reactiva
        this.stores = [...this.stores]
      }
    }
  }
}
</script>

<style scoped>
.copy-section {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.copy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cancel-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  color: #666;
}

.cancel-button:hover {
  color: #ff4444;
}

.select-store {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.copy-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
