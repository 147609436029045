export default [
  {
    path: "/product/edit/:id",
    // name: "productEdit",
    component: () => import("../../views/products/edit.vue"),
    // meta: { requiresAuth: true, role: "admin" },
    // children: [
    //   {
    //     path: "edit/:id",
    //     component: () => import("../../views/products/edit.vue"),
    //   },
    //   {
    //     path: "create/:id",
    //     component: () => import("../../views/products/create.vue"),
    //   },
    // ],
  },
  {
    path: "/product/create/:id",
    // name: "productCreate",
    component: () => import("../../views/products/create.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("../../views/products/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/products/images",
    name: "products-images",
    component: () => import("../../views/products/images/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/products/view",
    name: "products-view",
    component: () => import("../../views/products/views/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/stores",
    // name: "products",
    component: () => import("../../views/stores/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("../../views/categories/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
  {
    path: "/shops-list",
    name: "shops-list",
    component: () => import("../../views/shops/index.vue"),
    // meta: { requiresAuth: true, role: "admin" },
  },
];
