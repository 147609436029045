<template>
    <div class="modal fade show" tabindex="-1" style="display: block; background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar Configuraciones</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <form>
              <div
                v-for="(config, index) in localMetaTag"
                :key="config.id"
                class="mb-3"
              >
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'config-' + config.title" class="col-form-label">Title:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="localMetaTag[index].title"
                            type="text" 
                            :id="'config-' + config.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'config-' + config.id" class="col-form-label">Route:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="localMetaTag[index].route"
                            type="text" 
                            :id="'config-' + config.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'config-' + config.id" class="col-form-label">Image:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="localMetaTag[index].image"
                            type="text" 
                            :id="'config-' + config.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'config-' + config.id" class="col-form-label">Description:</label>
                    </div>
                    <div class="col-auto">
                        <textarea 
                            v-model="localMetaTag[index].description"
                            class="form-control" 
                            name="" 
                            :id="'config-' + config.id"
                            rows="3"
                            cols="75"
                        ></textarea>
                        
                    </div>
                </div>

                <hr>


              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="saveChanges">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      configs: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        localMetaTag: [], // Copia local de las configuraciones
      };
    },
    watch: {
      configs: {
        immediate: true,
        handler(newConfigs) {
          this.localMetaTag = JSON.parse(JSON.stringify(newConfigs)); // Crear una copia profunda
        },
      },
    },
    methods: {
      saveChanges() {
        this.$emit("save", this.localMetaTag); // Enviar los datos editados al padre
      },
      closeModal() {
        this.$emit("close"); // Cerrar el modal
      },
    },
  };
  </script>
  
  <style scoped>
  .modal.fade.show {
    display: block;
    background: rgba(0, 0, 0, 0.5); /* Fallback para el overlay */
  }
  </style>
  