<template>
  <div 
    class="store-item" 
    :class="{ 'selected': isSelected }"
  >
    <div style="display: flex;">
      <h3>{{ store.name }}</h3> 

      <button
        type="button"
        class="btn btn-primary"
        @click="createConfigs"
      >
        Create Configs
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="selectStore"
      >
        Select
      </button>

    </div>
    
    
    <div class="domains">
      <strong>Dominios:</strong>
      <ol>
        <li v-for="slug in store.slugs" :key="slug">
          {{ slug }}
        </li>
      </ol>
    </div>
    
    <div class="products">
      <strong>Productos ({{ store.products.length }}):</strong>
      <ol>
        <li v-for="product in normalizedProducts" :key="product.id">
          <span>id: {{ product.id }} - {{ product.status }}- {{ product.title }}</span>
          <button 
            @click.stop="removeProduct(product)" 
            class="remove-button"
            :disabled="isLoading"
          >
            <span>×</span>
          </button>
          
        </li>
      </ol>
    </div>
    
    <div class="meta-tags" v-if="normalizedMetaTags">
      <span>
        <strong>Meta Tags: </strong>
        <button @click="openEditModalMetaTag">Editar</button>
        <button @click="openAddModalMetaTag">Crear</button>
      </span>
      <!-- Modal para editar configuración -->
      <EditModalMetaTag
        v-if="metaTag.isModalOpen"
        :configs="normalizedMetaTags"
        @close="metaTag.isModalOpen = false"
        @save="updateMetaTag"
      />
      <ModalCreateMetaTag
        v-if="addMetaTag.isModalOpen"
        @close="addMetaTag.isModalOpen = false"
        @save="createMetaTag"
      />
      <ul>
        <li v-for="tag in normalizedMetaTags" :key="tag.id">
          {{ tag.id }} - {{ tag.route }} - {{ tag.title }}
          <button 
            @click.stop="removeMetaTag(tag)" 
            class="remove-button"
            :disabled="isLoading"
          >
            <span>×</span>
          </button>
        </li>
      </ul>
    </div>
    
    <div class="shop_configs" v-if="normalizedPageConfigs.length">
      <span>
        <strong>Page Configs: </strong>
        <button @click="openEditModalPageConfig">Editar</button>
      </span>
      <!-- Modal para editar configuración -->
      <EditModalConfig
        v-if="pageConfig.isModalOpen"
        :configs="normalizedPageConfigs"
        @close="pageConfig.isModalOpen = false"
        @save="updateConfigs"
      />
      <ul>
        <li v-for="config in normalizedPageConfigs" :key="config.id">
          <!-- Mostrar título -->
          {{ config.name }} : {{ config.body}}
          <button 
            @click.stop="removePageConfig(config)" 
            class="remove-button"
            :disabled="isLoading"
          >
            <span>×</span>
          </button>
          
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

import EditModalConfig from '@/components/Shops/Modals/editShopConfig.vue';
import EditModalMetaTag from '@/components/Shops/Modals/editShopMetaTag.vue';
import ModalCreateMetaTag from '@/components/Shops/Modals/createMetaTag.vue';

export default {
  name: 'StoreItem',
  components: {
    EditModalConfig,
    EditModalMetaTag,
    ModalCreateMetaTag,
  },
  
  props: {
    store: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      localPageConfigs: [], // Copia local de normalizedPageConfigs
      pageConfig:{
        showModal: false,
        selectedConfig: null,
        isModalOpen: false, // Controla la visibilidad del moda
      },
      metaTag:{
        showModal: false,
        selectedConfig: null,
        isModalOpen: false, // Controla la visibilidad del moda
      },
      addMetaTag:{
        showModal: false,
        selectedConfig: null,
        isModalOpen: false, // Controla la visibilidad del moda
      },
    }
  },

  computed: {
    normalizedMetaTags() {
      // Verificar si mata_tag existe y es un array
      if (!this.store.meta_tag || !Array.isArray(this.store.meta_tag)) {
        return [];
      }
      return this.store.meta_tag;
    },
    
    normalizedPageConfigs() {
      // Verificar si mata_tag existe y es un array
      if (!this.store.shop_configs || !Array.isArray(this.store.shop_configs)) {
        return [];
      }
      // return this.store.shop_configs;
      return this.store.shop_configs.map((config) => {
        return {
          ...config,
          editing: false,
          newBody: "",
        };
      });

    },

    normalizedProducts() {
      // Verificar si products existe y es un array
      if (!this.store.products || !Array.isArray(this.store.products)) {
        return [];
      }
      return this.store.products;
    }
  },

  methods: {
    openEditModalMetaTag() {
      console.log("openEditModal")
      this.metaTag.isModalOpen  = true;
    },
    closeModalMetaTag() {
      this.metaTag.showModal = false;
      this.metaTag.selectedConfig = null;
    },
    openEditModalPageConfig() {
      console.log("openEditModal")
      this.pageConfig.isModalOpen  = true;
    },
    closeModalPageConfig() {
      this.pageConfig.showModal = false;
      this.pageConfig.selectedConfig = null;
    },
    openAddModalMetaTag() {
      console.log("openEditModal")
      this.addMetaTag.isModalOpen  = true;
    },
    closeModalAddMetaTag() {
      this.addMetaTag.showModal = false;
      this.addMetaTag.selectedConfig = null;
    },
    async updateConfigs(updatedConfigs) {
      try {
        const url = this.appDomainApi + "shops/shopstore/page-config/update";
        // Realizar la solicitud para guardar todos los cambios
        await axios.put(url, { configs: updatedConfigs });
        alert("¡Configuraciones actualizadas con éxito!");
        this.isModalOpen = false;
      } catch (error) {
        console.error("Error al actualizar configuraciones:", error);
        alert("Hubo un error al actualizar las configuraciones.");
      }
    },
    async updateMetaTag(updatedConfigs) {
      try {
        const url = this.appDomainApi + "shops/shopstore/meta-tag/update";
        // Realizar la solicitud para guardar todos los cambios
        await axios.put(url, { configs: updatedConfigs });
        alert("¡Configuraciones actualizadas con éxito!");
        this.isModalOpen = false;
      } catch (error) {
        console.error("Error al actualizar configuraciones:", error);
        alert("Hubo un error al actualizar las configuraciones.");
      }
    },
    async createMetaTag(updatedConfigs, config) {
      console.log("createMetaTag")
      console.log(this.store.id)
      console.log(updatedConfigs)
      try {
        const url = this.appDomainApi + "shops/shopstore/meta-tag/create";
        const data = {
          shop_store_id: this.store.id,
          configs: updatedConfigs,
        }
        // Realizar la solicitud para guardar todos los cambios
        await axios.post(url, data);
        alert("¡Configuraciones actualizadas con éxito!");
        this.isModalOpen = false;
      } catch (error) {
        console.error("Error al actualizar configuraciones:", error);
        alert("Hubo un error al actualizar las configuraciones.");
      }
    },

    
    async createConfigs(){
      console.log("createConfigs")

      try {
        this.isLoading = true

        const url = this.appDomainApi + "shops/shopstore/page-config/create";
        const name = this.store.name;

        const response = await axios.post(url, {name})

        if (response.data.success) {
          alert('Productos copiados exitosamente')
        }
      } catch (error) {
        console.error('Error al copiar productos:', error)
        alert('Error al copiar productos')
      } finally {
        this.isLoading = false
      }

    },
    updateStore() {
      this.$emit('update-store', this.store)
    },
    selectStore() {
      console.log('Emitiendo select-store con:', this.store) // Debug
      this.$emit('select-store', this.store)
    },
    async removeProduct(product) {
      if (!confirm(`¿Estás seguro de eliminar todas las relaciones de "${product.title}"?`)) {
        return;
      }
      try {
        this.isLoading = true
        const data = {
          store_name: this.store.name
        }
        
        const url = this.appDomainApi + `shops/list/products/${product.id}/store-relations`;
        const response = await axios.delete(url, {data})
        
        if (response.data.success) {
          this.$emit('product-removed', {
            storeId: this.store.id,
            productId: product.id
          })
        }
      } catch (error) {
        console.error('Error al eliminar producto:', error)
        alert('Error al eliminar el producto')
      } finally {
        this.isLoading = false
      }
    },
    async removePageConfig(pageConfig) {
      if (!confirm(`¿Estás seguro de eliminar todas las relaciones de "${pageConfig.title}"?`)) {
        return;
      }
      try {
        this.isLoading = true

        const url = this.appDomainApi + `shops/shopstore/page-config/delete/${pageConfig.id}`;
        const response = await axios.delete(url)
        
        // if (response.data.success) {
        //   this.$emit('product-removed', {
        //     storeId: this.store.id,
        //     productId: product.id
        //   })
        // }
      } catch (error) {
        console.error('Error al eliminar producto:', error)
        alert('Error al eliminar el producto')
      } finally {
        this.isLoading = false
      }
    },
    async removeMetaTag(metaTag) {
      if (!confirm(`¿Estás seguro de eliminar todas las relaciones de "${metaTag.route}"?`)) {
        return;
      }
      try {
        this.isLoading = true
        
        const url = this.appDomainApi + `shops/shopstore/meta-tag/delete/${metaTag.id}`;
        const response = await axios.delete(url)
        
        // if (response.data.success) {
        //   this.$emit('product-removed', {
        //     storeId: this.store.id,
        //     productId: product.id
        //   })
        // }
      } catch (error) {
        console.error('Error al eliminar producto:', error)
        alert('Error al eliminar el producto')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.store-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
}
.store-item.selected {
  border-color: #4CAF50;
  background-color: #f0fff0;
}
.domains, .products, .meta-tags, .shop_configs {
  margin-top: 10px;
}

ul {
  list-style-type: none;
  padding-left: 15px;
}

li {
  margin: 5px 0;
}
.remove-button {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 18px;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.remove-button:hover {
  background-color: #ffeeee;
}

.remove-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}


</style>