<template>
  <div>
    <StoreList 
      :data="storeData" 
      :stores="storeData" 
      @store-updated="handleStoreUpdate"
      @product-removed="handleProductRemoved"
    />
  </div>
</template>

<script>
import axios from 'axios'

import StoreList from '@/components/Shops/MergeShops.vue'

export default {
  name: 'Shops',
  
  components: {
    StoreList
  },

  created() {
        this.getStores()
    },
  
  data() {
    return {
      storeData: [
        {
          id: 1,
          name: "TiendaPapaya",
          slug: "domain.com",
          products: [{id: 3, title: "Product"}],
          mata_tag: [{ id: 1, route: "index", title: "Domain" }]
        },
        {
          id: 2,
          name: "TiendaPapaya",
          slug: "www.domain.com",
          products: [{id: 3, title: "Product"}],
          mata_tag: [{ id: 1, route: "index", title: "Domain" }]
        }
      ]
    }
  },

  methods: {
    handleStoreUpdate(store) {
      console.log('Tienda actualizada:', store)
    },
    getStores() {
        const url = this.appDomainApi + "shops/list/index";
        

        axios.get(url,)
            .then(res => {
                console.log(res.data)
                this.storeData = res.data
            })
            .catch(err => {
                console.error(err);
            })
    },
    handleProductRemoved({ storeId, productId }) {
      console.log("handleProductRemoved")
      // Actualizar tu estado global o hacer una nueva petición para recargar los datos
      this.loadStores()
    }
    
  }
}
</script>