<template>
    <div>
        <!-- TITLE -->
        <h1 class="text-center">Stores
            <div type="button" class="btn btn-primary" v-on:click="openModalProductJson">
                <b>Add Json</b>
            </div>
        </h1>

        <SlideStores :posts="posts.data" :thumbnails="thumbnails" />

        <!-- Button trigger modal - PRODUCT JSON  -->
        <a type="button" id="btnModalProductJson" hidden class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#modalProductJson">
            Launch demo modal
        </a>

        <!-- Modal - PRODUCT JOSN -->
        <div class="modal fade" id="modalProductJson" tabindex="-1" aria-labelledby="orderModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="orderModalLabel">Products Json</h5>
                        <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
                    </div>
                    <div class="modal-body">

                        <textarea v-model="productJson" cols="100" rows="10"></textarea>


                        <div v-for="(value, key) in filteredData" :key="key" class="data-row">
                            <label>{{ key }}:</label>
                            <input type="text" :value="value" readonly ref="inputField" />
                            <button @click="copyToClipboard(value)">Copy</button>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
                        <a type="button" class="btn btn-primary" @click="storeJsonSave">Save</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'

import SlideStores from '@/components/Slideshow/Stores/Items.vue'

export default {
    name: 'Stores',
    components: {
        SlideStores
    },
    data() {
        return {
            posts: [],
            thumbnails: false,
            productJson: null,
        }
    },
    mounted() {
        this.getStores()
    },
    computed: {
        filteredData() {
            if (this.productJson) {
                const parsedData = JSON.parse(this.productJson);
                // Filtrar las claves que no incluyen 'domain'
                return Object.fromEntries(Object.entries(parsedData).filter(([key]) => !key.includes('domain')));
            } else {
                return []
            }
        },
    },
    methods: {
        getStores() {
            console.log("getStores")
            const url = this.appDomainApi + "shops/stores/index";

            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.posts = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        openModalProductJson() {
            document.getElementById('btnModalProductJson').click();
        },
        storeJsonSave() {
            console.log("storeJsonSave")
            
            const parsedData = JSON.parse(this.productJson);
            
            // Paso 2: Crear el objeto FormData y agregar los pares clave-valor
            const formData = new FormData();
            for (const [key, value] of Object.entries(parsedData)) {
                if (!key.includes('domain')) {
                    formData.append(key, value);
                }
            }
            formData.append('domain_id', 1);

            console.log(...formData)
            // //let bodyData = JSON.stringify(this.productJson);
            // let bodyData = this.productJson;

            // const data = new FormData();
            // data.append("store", bodyData);


            const url = this.appDomainApi + "shops/store/json"

            axios.post(url, formData)
              .then(res => {
                console.log(res)
                this.productJson=null
              })
              .catch(err => {
                console.error(err);
              })
        },
        copyToClipboard(value) {
            const tempInput = document.createElement('input');
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            // document.body.removeChild(tempInput);
            alert('Valor copiado: ' + value);
        },
    },
}
</script>
<style scoped></style>