<template>
    <div>
        <p>create product000</p>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>