<template>
    <div class="modal fade show" tabindex="-1" style="display: block; background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Meta Tag</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <form>
              
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'metaTag-' + metaTag.title" class="col-form-label">Title:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="metaTag.title"
                            type="text" 
                            :id="'metaTag-' + metaTag.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'metaTag-' + metaTag.id" class="col-form-label">Route:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="metaTag.route"
                            type="text" 
                            :id="'metaTag-' + metaTag.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'metaTag-' + metaTag.id" class="col-form-label">Image:</label>
                    </div>
                    <div class="col-auto">
                        <input 
                            v-model="metaTag.image"
                            type="text" 
                            :id="'metaTag-' + metaTag.id"
                            class="form-control" 
                            aria-describedby="passwordHelpInline"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label :for="'metaTag-' + metaTag.id" class="col-form-label">Description:</label>
                    </div>
                    <div class="col-auto">
                        <textarea 
                            v-model="metaTag.description"
                            class="form-control" 
                            name="" 
                            :id="'metaTag-' + metaTag.id"
                            rows="3"
                            cols="75"
                        ></textarea>
                        
                    </div>
                </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="saveChanges">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // props: {
    //   configs: {
    //     type: Array,
    //     required: true,
    //   },
    // },
    data() {
      return {
        localMetaTag: [], // Copia local de las configuraciones
        metaTag:{}
      };
    },
    
    methods: {
      saveChanges() {
        // this.$emit("save", this.metaTag, this.configs); // Enviar los datos editados al padre
        this.$emit("save", this.metaTag); // Enviar los datos editados al padre
      },
      closeModal() {
        this.$emit("close"); // Cerrar el modal
      },
    },
  };
  </script>
  
  <style scoped>
  .modal.fade.show {
    display: block;
    background: rgba(0, 0, 0, 0.5); /* Fallback para el overlay */
  }
  </style>
  